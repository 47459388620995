@import '~amo/css/styles';
@import '~amo/components/IconPromotedBadge/styles';

.PromotedBadge {
  align-items: flex-start;
  display: flex;
  font-size: $font-size-xs;
  margin-bottom: 6px;

  &.PromotedBadge-large {
    font-size: $font-size-default;
  }

  .Addon-theme & {
    @include margin-end(12px);
  }
  @include respond-to(large) {
    justify-content: flex-end;

    .Addon-theme & {
      justify-content: flex-start;
    }
  }
}

.PromotedBadge--line {
  color: $grey-30;
}

.PromotedBadge--recommended {
  color: $orange-50;
}

.PromotedBadge-link {
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  max-height: $icon-promoted-small-size;

  .PromotedBadge-large & {
    max-height: $icon-promoted-large-size;
  }

  &,
  &:link,
  &:visited {
    font-weight: normal;
    text-decoration: none;
  }
}

.PromotedBadge-link--line {
  border: 1px solid $grey-30;

  @include border-start(0);

  &,
  &:link,
  &:visited {
    color: $grey-30;
  }

  &:active,
  &:focus,
  &:hover {
    border-color: $grey-40;
    color: $grey-40;

    .IconPromotedBadge-circle-bgColor--line {
      fill: $grey-40;
    }
  }
}

.PromotedBadge-link--recommended {
  border: 1px solid $orange-50;

  @include border-start(0);

  &,
  &:link,
  &:visited {
    color: $orange-50;
  }

  &:active,
  &:focus,
  &:hover {
    border-color: $orange-60;
    color: $orange-60;

    .IconPromotedBadge-circle-bgColor--recommended {
      fill: $orange-60;
    }
  }
}

.PromotedBadge-label {
  @include margin-start(6px);
  @include margin-end(8px);
}

.PromotedBadge-label--line {
  color: $grey-90;
}

.PromotedBadge-label--recommended {
  color: $orange-80;
}
