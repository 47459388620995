@import '~amo/css/styles';

$icon-default-size: 32px;

.SearchResult {
  list-style-type: none;
  margin: 0 0 1px;

  &:hover {
    cursor: pointer;

    .SearchResult-link {
      color: $link-color;
    }
  }
}

.SearchResult-link {
  // This margin gives the recommended badge some space.
  @include margin-end(8px);

  text-decoration: none;

  &,
  &:active,
  &:link,
  &:visited {
    color: $text-color-default;
  }

  &:focus,
  &:hover {
    color: $link-color;
  }
}

.SearchResult-result {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  max-width: $theme-width-default;
  padding: 0;
  width: 100%;
}

.SearchResult-icon-wrapper {
  order: 1;

  .SearchResult--theme & {
    flex-grow: 1;
    margin-bottom: 10px;
    overflow: hidden;
    width: calc(100% + 20px);
  }

  @include respond-to(large) {
    border-radius: $border-radius-default;
  }
}

.SearchResult-icon-wrapper--no-theme-image {
  align-items: center;
  background: #ccc;
  color: $black;
  display: flex;
  font-size: $font-size-default;
  font-weight: normal;
  height: 100%;
  justify-content: center;
  text-align: center;
  text-shadow: 0 0 2px $white;
}

.SearchResult-icon {
  display: inline-block;
  height: $icon-default-size;
  width: $icon-default-size;

  .SearchResult--theme & {
    border-radius: $border-radius-default;
    display: block;
    height: auto;
    object-fit: cover;
    object-position: top left;
    width: 100%;
  }

  &.SearchResult-icon--loading {
    .SearchResult--theme & {
      height: $icon-default-size;
      width: $icon-default-size;
    }
  }
}

.SearchResult-contents {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: space-between;
  margin: 0 10px;
  order: 1;

  // `width: 0;` allows the users section to expand.
  width: 0;

  .SearchResult--theme & {
    @include respond-to(medium) {
      @include margin-start(0);

      width: 50%;
    }
  }
}

.SearchResult-name {
  @include font-medium;

  align-items: flex-start;
  color: $type-black;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
  font-size: $font-size-default;
  line-height: $line-height-compressed;
  margin: 0;
  padding: 0;
  row-gap: 6px;
  text-decoration: none;
  width: 100%;

  .PromotedBadge {
    justify-content: flex-start;
    margin-top: 6px;
  }

  @include respond-to(extraLarge) {
    flex-direction: row;
    margin-top: 0;

    .PromotedBadge {
      margin-top: 0;
    }
  }

  .LoadingText {
    margin-bottom: 5px;
  }
}

.SearchResult-summary {
  display: none;

  @include respond-to(medium) {
    color: $type-black;
    display: block;
    flex-grow: 1;
    font-size: $font-size-s;
    font-weight: normal;
    margin: 0;
    max-width: 465px;
    width: 100%;
  }
}

.SearchResult-metadata {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  width: 100%;
}

.SearchResult-rating {
  @include margin-end(10px);

  display: inline-block;
  width: auto;

  .Rating {
    justify-content: flex-start;
    margin: 0;
  }

  .SearchResult--theme & {
    width: auto;
  }
}

.SearchResult-author {
  display: none;
  word-break: break-all;

  @include respond-to(medium) {
    display: block;
  }
}

.SearchResult-users-icon {
  height: 12px;
  margin-bottom: -1px;
}

.SearchResult-users {
  @include margin-start(39.5px);

  flex-grow: 1;
  order: 3;
  width: 100%;

  @include respond-to(medium) {
    @include margin-start(0);

    flex-grow: 0;
    order: 1;
    width: auto;
  }

  .SearchResult--theme & {
    @include margin-start(7.5px);

    @include respond-to(medium) {
      @include margin-start(-2.5px);
    }
  }
}

.SearchResult--meta-section {
  color: $sub-text-color;
  font-size: $font-size-s;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.SearchResult-note {
  background-color: transparentize($blue-50, 0.95);
  border-radius: $border-radius-default;
  color: $type-black;
  flex-grow: 1;
  font-weight: normal;
  margin-top: 12px;
  padding: 12px;
  width: 100%;

  @include respond-to(medium) {
    @include margin-start(12px);
  }
}

.SearchResult-note-header {
  margin: 0 0 6px;

  .Icon-comments-blue {
    @include margin-end(12px);
  }
}
